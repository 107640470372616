import React from 'react';
import PropTypes from 'prop-types';
import Image from '../../ui/Image/Image';
import Page from '../../pages/Page';
import AppLink from '../../ui/links/AppLink';
import Cms from '../Cms/Cms';
// import Message from '../../ui/Messages/Message';
import Form from './Form';

import texts from './ContactTexts.json';

import './Contact.scss';

/* <Message>
    <Cms html={texts[lang].details} />
</Message> */

const Contact = ({ lang }) => (
    <Page title="Kontakt | Mya idé">
        <section>
            <div className="contact">

                <Image
                    alt="Stenar"
                    src="/images/bildspel1a.jpg"
                    ratio={940 / 300}
                />

                <Image
                    alt="Fossil"
                    src="/images/bildspel3a.jpg"
                    ratio={940 / 300}
                />

                <div className="contact-info">
                    <Cms html={texts[lang].details} />
                </div>

                <Form texts={texts[lang]} />

                <div className="contact-col-image">
                    <div className="image-round">
                        <Image
                            className="round"
                            alt="Ann-Charlotte Garhammar"
                            src="/images/mor_round.jpg"
                            ratio={1}
                        />
                    </div>
                </div>

                <div className="contact-col-info">

                    <Cms html={texts[lang].owner} />

                    <div className="olandsguide-logo">
                        <Image
                            alt="Ölandsguide"
                            src="/images/olandsguide.jpg"
                            ratio={75 / 122}
                        />
                    </div>
                    <div className="olinks">
                        <AppLink
                            to='http://www.olandsguiderna.se'
                            external
                        >
                            www.olandsguiderna.se
                        </AppLink>
                        <br />
                        <AppLink
                            to='http://www.oland.se'
                            external
                        >
                            www.oland.se
                        </AppLink>
                        <br />
                        <AppLink
                            to='http://www.visitoland.com'
                            external
                        >
                            www.visitoland.com
                        </AppLink>
                    </div>
                </div>
            </div>
        </section>
    </Page>
);


Contact.propTypes = {
    lang: PropTypes.string.isRequired,
};

export default Contact;
//
// <Image
//     alt="Ann-Charlotte Garhammar"
//     src="/images/mor1.jpg"
//     ratio={1000 / 750}
// />
