import React from "react";
import Logo from "../../ui/icons/Logo";
import Image from "../../ui/Image/Image";
import AppLink from "../../ui/links/AppLink";
import Cms from "../Cms/Cms";

import texts from "../../pages/Home/HomeTexts.json";

import "./Footer.scss";

const Footer = ({ lang }) => (
  <div className="footer-wrapper">
    <footer id="footer">
      <div className="footer-mya">
        <ul>
          <li>Mya idé</li>
          <li>Org. nr 490313-3025</li>
          <li>
            <AppLink external to="mailto:mya.oland@gmail.com">
              mya.oland@gmail.com
            </AppLink>
          </li>
        </ul>

        <div className="olandsguide-logo">
          <AppLink to="http://www.olandsguiderna.se" external>
            <Image
              alt="Ölandsguide"
              src="/images/olandsguide_80x127.jpg"
              ratio={80 / 127}
            />
          </AppLink>
        </div>

        <div className="olinks">
          <AppLink to="http://www.olandsguiderna.se" external>
            www.olandsguiderna.se
          </AppLink>
          <br />
          <AppLink to="http://www.oland.se" external>
            www.oland.se
          </AppLink>
          <br />
          <AppLink to="http://www.visitoland.com" external>
            www.visitoland.com
          </AppLink>
        </div>
      </div>

      <div className="footer-logo">
        <Logo />
        <div className="disclaimer">&copy; 2018 Mya idé</div>
      </div>

      <div className="footer-contact">
        <ul>
          <li>Ann-Charlotte Garhammar</li>
          <li>Stensövägen 63E</li>
          <li>392 57 Kalmar</li>
          <li>+46 707 537890</li>
        </ul>
      </div>
    </footer>
    <div className="gdpr">
      <div className="gdpr-inner">
        <Cms html={texts[lang].gdpr} />
      </div>
    </div>
  </div>
);

export default Footer;
