import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import cx from "classnames";
import PropTypes from "prop-types";
import ContactContainer from "./components/Contact/ContactContainer";
import HomeContainer from "./pages/Home/HomeContainer";
import ShinesContainer from "./pages/Shines/ShinesContainer";
import SensesContainer from "./pages/Senses/SensesContainer";
import ByerumContainer from "./pages/Byerum/ByerumContainer";
import StoneContainer from "./pages/Stone/StoneContainer";
import FossilContainer from "./pages/Fossil/FossilContainer";
import ChurchContainer from "./pages/Church/ChurchContainer";
import ImagesContainer from "./pages/Images/ImagesContainer";
import StoriesContainer from "./pages/Stories/StoriesContainer";
import BoneContainer from "./pages/Bone/BoneContainer";
import NoMatch from "./components/NoMatch/NoMatch";
import HeaderContainer from "./components/Header/HeaderContainer";
import MobileMenuContainer from "./components/MobileMenu/MobileMenuContainer";
import Footer from "./components/Footer/Footer";

import "normalize.css";
import "./ui/typography.scss";
import "./App.scss";

const App = ({ lang, initialized, media, position, isMenuOpen, closeMenu }) => {
  const classes = cx(
    "app",
    lang.toLowerCase(),
    initialized,
    media.toLowerCase(),
    {
      "has-scroll-offset": position > 80,
      "has-open-menu": isMenuOpen,
    }
  );

  return (
    <div className={classes}>
      <Router>
        <div>
          <HeaderContainer />
          <Switch>
            <Route exact path="/" component={HomeContainer} />
            <Route path="/kontakt" component={ContactContainer} />
            <Route path="/öland-spirar" component={ShinesContainer} />
            <Route path="/oland-spirar" component={ShinesContainer} />
            <Route path="/sinnenas-oland" component={SensesContainer} />
            <Route path="/sinnenas-öland" component={SensesContainer} />

            <Route path="/kalla-gamla-kyrka" component={ChurchContainer} />

            <Route path="/bild-och-berattelse" component={StoriesContainer} />

            <Route path="/stenen-berättar" component={StoneContainer} />
            <Route path="/sten-och-strand" component={FossilContainer} />
            <Route path="/bilder-i-natten" component={ImagesContainer} />
            <Route path="/vid-byerums-raukar" component={ByerumContainer} />
            <Route path="/vid-byrums-raukar" component={ByerumContainer} />
            <Route path="/sten-och-ben" component={BoneContainer} />

            <Route path="/horns-udde" component={FossilContainer} />
            <Route path="/spännande-stenbrott" component={StoneContainer} />
            <Route path="/byrums-raukar" component={ByerumContainer} />
            <Route component={NoMatch} />
          </Switch>
          <MobileMenuContainer />
          <Footer lang={lang} />
        </div>
      </Router>
      <div
        id="curtain"
        onClick={closeMenu}
        role="presentation"
        onKeyDown={closeMenu}
      />
    </div>
  );
};

App.propTypes = {
  initialized: PropTypes.bool.isRequired,
  media: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
  closeMenu: PropTypes.func.isRequired,
  lang: PropTypes.string,
};

App.defaultProps = {
  lang: "",
};

export default App;
