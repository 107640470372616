/* globals window, URLSearchParams */

import React from 'react';
import ReactDOM from 'react-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import Utils from './utils/Utils';
import AppContainer from './AppContainer';

// import registerServiceWorker from './registerServiceWorker';
// import { unregister } from './registerServiceWorker';


function reducer(state, action) {
    function newState(valuePath) {
        const value = action.payload;
        return Utils.deepUpdate(state, valuePath, value);
    }

    function newStates(...pathsAndValues) {
        let updatedState = state;

        pathsAndValues.forEach(([valuePath, value]) => {
            updatedState = Utils.deepUpdate(updatedState, valuePath, value);
        });

        return updatedState;
    }

    switch (action.type) {
        case 'UI_INITIALIZED':
            return newState('ui.initialized');
        case 'UI_SET_LANG':
            return newStates(
                ['ui.lang', action.payload],
                ['ui.isMenuOpen', false],
            );
        case 'UI_SET_MEDIA':
            return newStates(
                ['ui.media', action.payload.media],
                ['ui.width', action.payload.width],
            );
        case 'UI_SET_SCROLL':
            return newStates(
                ['ui.position', action.payload.position],
                ['ui.direction', action.payload.direction],
            );
        case 'UI_TOGGLE_MENU':
            return newState('ui.isMenuOpen');
        case 'LIBS_MAPS':
            return newState('libs.maps');
        default:
            return state;
    }
}

let entries = null;
let lang = 'swedish';

if (window.URLSearchParams) {
    entries = new URLSearchParams(window.location.search);
    lang = entries.get('lang');
    if (lang !== 'english' && lang !== 'german') {
        lang = 'swedish';
    }
}

function getApplicationState() {
    return {
        ui: {
            initialized: false,
            width: window.innerWidth,
            media: Utils.getMediaFromWidth(window.innerWidth),
            position: 0,
            direction: 'down',
            isMenuOpen: false,
            lang,
        },
        libs: {
            maps: false,
        }
    };
}

// eslint-disable-next-line no-underscore-dangle
const reduxStore = createStore(reducer, getApplicationState(), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());


ReactDOM.render(
    <Provider store={reduxStore}>
        <AppContainer />
    </Provider>,
    window.document.getElementById('root'),
    () => {
        function updateWidth() {
            const width = window.innerWidth;
            reduxStore.dispatch({
                type: 'UI_SET_MEDIA',
                payload: {
                    media: Utils.getMediaFromWidth(width),
                    width,
                },
            });
        }

        window.addEventListener('resize', updateWidth);
        window.addEventListener('orientationchange', updateWidth);

        reduxStore.dispatch({
            type: 'UI_INITIALIZED',
            payload: true,
        });

        /* eslint-disable */
        let last_known_scroll_position = 0;
        let direction_position = 0;
        let ticking = false;
        let direction = 'down';

        let passiveIfSupported = false;

        try {
            window.addEventListener("test", null, Object.defineProperty({}, "passive", { get: function () { passiveIfSupported = { passive: true }; } }));
        } catch (err) {}

        window.addEventListener('scroll', function () {
            last_known_scroll_position = window.scrollY;
            if (!ticking) {
                if (last_known_scroll_position > direction_position) {
                    direction = 'down';
                } else {
                    direction = 'up';
                }
                direction_position = last_known_scroll_position;
                window.requestAnimationFrame(function () {
                    reduxStore.dispatch({
                        type: 'UI_SET_SCROLL',
                        payload: {
                            position: last_known_scroll_position,
                            direction: direction,
                        }
                    });
                    ticking = false;
                });
            }
            ticking = true;
        }, passiveIfSupported);
        /* eslint-enable */

        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register('/sw.js');
        }
    }

);
// registerServiceWorker();

// unregister();
