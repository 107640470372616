import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Page from "../Page";
import Dates from "../../components/Calendar/Dates";
import Utils from "../../utils/Utils";
import AppLink from "../../ui/links/AppLink";
import Image from "../../ui/Image/Image";

import "./Bone.scss";

const Bone = (props) => {
  const { lang, media } = props;
  const useFullWidth = !(
    media === Utils.media.MOBILE ||
    media === Utils.media.LANDSCAPE ||
    media === Utils.media.TABLET ||
    media === Utils.media.DESKTOP
  );

  const isSwedish = lang === "swedish";
  let title = "Sten och ben";
  if (lang === "english") {
    title = "Stone and bone";
  } else if (lang === "german") {
    title = "Stein und Knochen";
  }

  return (
    <Page title="Sten och ben | Mya idé">
      <section>
        <div className="bone-page">
          <div className="image-wrapper">
            <Image
              alt="Ölandsk kustlinje"
              src="/images/sten_och_ben_1390x537.jpg"
              ratio={1390 / 537}
            />
          </div>

          <div
            className={cx("calendar", {
              full: useFullWidth,
            })}
          >
            <Dates useFullWidth={useFullWidth} lang={lang} />

            <div className="calendar-info bone">
              <div className="calendar-type">
                <div className="calendar-type-header">
                  <div className="bg" />
                  <h3>{title}</h3>
                  {isSwedish ? (
                    <span>tillsammans med Ölandsguiden Aili Käärik</span>
                  ) : null}
                </div>
                {isSwedish ? (
                  <ul>
                    <li>
                      <b>Kl 18 - ca 20.30</b>
                    </li>
                    <li>
                      <strong>150 kr/p</strong>
                    </li>
                    <li>
                      Föranmäl, gärna via SMS, till 0707-537890 (Ann-Charlotte)
                    </li>
                    <li>
                      <span>Mötesplats:</span> Vid norra nedfartsvägen till
                      Gillberga Kultur- och Naturbrott. Se guideflagga.
                    </li>
                    <li>Vandring ca 2 km.</li>
                    <li>
                      <span>Gillberga:</span>{" "}
                      <AppLink external to="https://goo.gl/maps/taoQ5gP6B272">
                        Latitud 57,1110, longitud 16,8956
                      </AppLink>
                    </li>
                  </ul>
                ) : null}
                <div className="small">
                  Steniga minnen i olika form berättar om svunna tider.
                  Ölandsguiderna Aili och Ann-Charlotte tar er med på en resa
                  tillbaka i tiden och söker rätt på hällkista och mytisk
                  treudd. Äldre tider har lämnat synliga spår i landskapet och
                  de geologiska lagren berättar om öländsk fossil och
                  utvecklingen under miljontals år.
                </div>
                {isSwedish ? (
                  <ul>
                    <li>
                      <span>Kontakt:</span> Ann-Charlotte Garhammar
                    </li>
                    <li>
                      <span>Telefon:</span> 0707 537890
                    </li>
                  </ul>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Page>
  );
};

Bone.propTypes = {
  media: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
};

export default Bone;
