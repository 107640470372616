import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
// import Icon from 'ui/icons/Icon';

import './Message.scss';

const Message = ({ type, className, children, lang, langFilter }) => {

    if (langFilter) {
        if (langFilter.indexOf(lang) === -1) {
            return null;
        }
    }

    const classes = cx('message', className, {
        'message-error': type === 'ERROR',
        'message-warning': type === 'WARNING',
        'message-info': type === 'INFO',
        'message-success': type === 'SUCCESS',
    });

    // let glyph;
    // if (type === 'ERROR') {
    //     glyph = 'INFO';
    // } else if (type === 'WARNING') {
    //     glyph = 'INFO';
    // } else if (type === 'INFO') {
    //     glyph = 'INFO';
    // } else {
    //     glyph = 'INFO';
    // }
    //
    // <Icon glyph={glyph} />
    return (
        <div className={classes}>
            {children}
        </div>
    );
};

Message.propTypes = {
    children: PropTypes.node.isRequired,
    lang: PropTypes.string,
    type: PropTypes.string,
    className: PropTypes.string,
    langFilter: PropTypes.array, // eslint-disable-line
};

Message.defaultProps = {
    type: 'ERROR',
    lang: 'nomatch',
    className: '',
    langFilter: null,
};


export default Message;
