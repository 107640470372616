import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import Contact from './Contact';

const ContactContainer = props => <Contact {...props} />;

const mapStateToProps = (state) => ({
    media: state.ui.media,
    lang: state.ui.lang,
});


export default connect(
  mapStateToProps,
)(ContactContainer);
