import React from "react";
import AppLink from "../../ui/links/AppLink";

const Images = ({ lang }) => {
  if (lang === "swedish") {
    return (
      <div className="images">
        <div className="calendar-type-header">
          <div className="bg" />
          <h3>Stenen som får Öland att spira!</h3>
        </div>
        <i className="calendar-type-sub-header">
          Kom till en lättsam guidning bland flisor och raukar - om sten, mager
          jord och lila blommor.
        </i>
        <AppLink to="/oland-spirar">
          <b>Läs mer</b>
        </AppLink>
      </div>
    );
  } else if (lang === "english") {
    return (
      <div className="images">
        <div className="calendar-type-header">
          <div className="bg" />
          <h3>Öland spring focus</h3>
        </div>
      </div>
    );
  } else {
    return (
      <div className="images">
        <div className="calendar-type-header">
          <div className="bg" />
          <h3>Öland Frühlingsfokus</h3>
        </div>
      </div>
    );
  }
};

export default Images;
