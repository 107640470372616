import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';

class Cms extends React.Component {

    static propTypes = {
        history: ReactRouterPropTypes.history.isRequired,
        html: PropTypes.node,
        className: PropTypes.string,
        TagName: PropTypes.string,
    };

    static defaultProps = {
        html: null,
        className: '',
        TagName: 'div',
    };

    cmsClickHandler = e => {

        const source = e.target || e.srcElement;
        if (source.tagName.toLowerCase() !== 'a') {
          return;
        }

        const href = source.getAttribute('href') || '';
        if (href.indexOf('://') > -1) {
          return;
        }

        if (href.indexOf('mailto:') > -1) {
          return;
        }

        if (href.indexOf('tel:') === 0) {
          return;
        }

        if (source.getAttribute('target') === '_blank') {
          return;
        }

        if (source.classList.contains('anchor')) {
          return;
        }

        e.preventDefault();
        const { pathname } = source;
        const { history } = this.props;
        history.push(pathname);

    }

    render() {
        const { html, className, TagName } = this.props;

        return (
            <TagName
                className={className}
                dangerouslySetInnerHTML={{ __html: html }}
                onClick={this.cmsClickHandler}
            />
        );
    }
}

Cms.propTypes = {
    html: PropTypes.node,
    className: PropTypes.string,
    TagName: PropTypes.string,
};

Cms.defaultProps = {
    html: null,
    className: '',
    TagName: 'div',
};

export default withRouter(Cms);
