import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Page from "../Page";
import Dates from "../../components/Calendar/Dates";
import Utils from "../../utils/Utils";
import AppLink from "../../ui/links/AppLink";
import Image from "../../ui/Image/Image";

import "./Stone.scss";

const Stone = (props) => {
  const { lang, media } = props;
  const useFullWidth = !(
    media === Utils.media.MOBILE ||
    media === Utils.media.LANDSCAPE ||
    media === Utils.media.TABLET ||
    media === Utils.media.DESKTOP
  );

  const isSwedish = lang === "swedish";

  let title = "4,6 miljarder år på 60 min";
  if (lang === "english") {
    title = "Stone and stories...";
  } else if (lang === "german") {
    title = "Stein und Geschichten...";
  }

  return (
    <Page title="4,6 miljarder år på 60 min | Mya idé">
      <section>
        <div className="stone-page">
          <div className="image-wrapper">
            {/* <Image
              alt="Fossiler i sten"
              src="/images/stenen_berattar_1390x500.jpg"
              ratio={1390 / 500}
            /> */}
            <Image
              alt="Ölandsk kustlinje"
              src="/images/sten_och_ben_1390x537.jpg"
              ratio={1390 / 537}
            />
          </div>

          <div
            className={cx("calendar", {
              full: useFullWidth,
            })}
          >
            <Dates useFullWidth={useFullWidth} lang={lang} />

            <div className="calendar-info stone">
              <div className="calendar-type">
                <div className="calendar-type-header">
                  <div className="bg" />
                  <h3>{title}</h3>
                </div>
                {isSwedish ? (
                  <>
                    <div className="small mt-20">
                      Möt den höga väggen i Gillberga bergbrott och förundras
                      över dess ålder och mäktighet. I jämförelse är människan
                      liten och dess tid på jorden kort. Vår planets historia
                      sägs vara 4,6 miljarder år gammal och själva lagret av
                      kalksten är nästan 500 miljoner år. Under en timme
                      fördjupar vi oss på lättsamt sätt i den spännande
                      geologin.
                    </div>
                    <ul>
                      {/* <li>
                        <span className="hide day-label">Söndag</span>
                        <b className="pictures-time">21 / 7</b> kl 11 - 12
                      </li>
                      <li>
                        <span className="hide day-label">Söndag</span>
                        <b className="pictures-time">28 / 7</b> kl 11 - 12
                      </li> */}
                      <li>
                        <span>Mötesplats:</span> Gillberga Natur- och
                        Kulturbrott
                      </li>
                      <li>
                        <strong>100 kr</strong> per person
                      </li>
                      <li>
                        <u>Anmälan till 0707 - 53 78 90</u>, gärna SMS
                      </li>
                      <li>
                        <span>Gillberga:</span>{" "}
                        <AppLink external to="https://goo.gl/maps/taoQ5gP6B272">
                          Latitud 57,1110, longitud 16,8956
                        </AppLink>
                      </li>
                      <li>
                        God tillgänglighet för funktionshindrade, vg ring.
                      </li>
                    </ul>
                  </>
                ) : null}

                {isSwedish ? (
                  <ul>
                    <li>
                      <span>Kontakt:</span> Ann-Charlotte Garhammar
                    </li>
                    <li>
                      <span>Telefon:</span> 0707 537890
                    </li>
                  </ul>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Page>
  );
};

Stone.propTypes = {
  media: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
};

export default Stone;
