import React from "react";
import cx from "classnames";

import "./Form.scss";

const statuses = {
  DEFAULT: "default",
  PENDING: "pending",
  SUCCESS: "success",
  ERROR: "error",
};

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

class Form extends React.Component {
  state = {
    name: "",
    email: "",
    text: "",
    status: statuses.DEFAULT,
  };

  handleSubmit = (e) => {
    this.setState({
      status: statuses.PENDING,
    });

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...this.state }),
    })
      .then(() => this.setState({ status: statuses.SUCCESS }))
      .catch((error) => {
        console.error(error);
        this.setState({ status: statuses.ERROR });
      });

    e.preventDefault();
  };

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

  reset = () => this.setState({ status: statuses.DEFAULT });

  render() {
    const { name, email, message, status } = this.state;
    const { texts } = this.props;

    return (
      <div className={cx("form", status)}>
        <div className="form-error">
          <strong>{texts.error_header}</strong>
          <p>{texts.error_text}</p>
          <div className="form-error-button">
            <button onClick={this.reset}>{texts.error_retry}</button>
          </div>
        </div>

        <div className="form-success">
          <strong>{texts.success_header}</strong>
          <p>{texts.success_text}</p>
        </div>

        <form onSubmit={this.handleSubmit}>
          <p>
            <label>
              <span>{texts.name}:</span>
              <input
                required
                type="text"
                name="name"
                value={name}
                onChange={this.handleChange}
              />
            </label>
          </p>
          <p>
            <label>
              <span>{texts.email}:</span>
              <input
                required
                type="email"
                name="email"
                value={email}
                onChange={this.handleChange}
              />
            </label>
          </p>
          <p>
            <label>
              <span>{texts.message}:</span>
              <textarea
                required
                name="message"
                value={message}
                onChange={this.handleChange}
              />
            </label>
          </p>
          <p className="submit-button-wrapper">
            <button type="submit">{texts.send}</button>
          </p>
        </form>
      </div>
    );
  }
}

export default Form;
