import React from "react";
import AppLink from "../../ui/links/AppLink";

const Stories = ({ lang }) => {
  if (lang === "swedish") {
    return (
      <div className="fossil">
        <div className="calendar-type-header">
          <div className="bg" />
          <h3>Bild och berättelse i Källa gamla kyrka</h3>
        </div>
        <i className="calendar-type-sub-header">
          om kyrka och källor,
          <br />
          om slott och kojor
        </i>
        <AppLink to="/bild-och-berattelse">
          <b>Läs mer</b>
        </AppLink>
      </div>
    );
  } else if (lang === "english") {
    return (
      <div className="fossil">
        <div className="calendar-type-header">
          <div className="bg" />
          <h3>Pictures in Källa old church</h3>
        </div>
      </div>
    );
  } else {
    return (
      <div className="fossil">
        <div className="calendar-type-header">
          <div className="bg" />
          <h3>Bilder in der alten Kirche von Källa</h3>
        </div>
      </div>
    );
  }
};

export default Stories;
