import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Page from "../Page";
import Dates from "../../components/Calendar/Dates";
import Utils from "../../utils/Utils";
import AppLink from "../../ui/links/AppLink";
import Image from "../../ui/Image/Image";

import "./Fossil.scss";

const Fossil = (props) => {
  const { lang, media } = props;
  const useFullWidth = !(
    media === Utils.media.MOBILE ||
    media === Utils.media.LANDSCAPE ||
    media === Utils.media.TABLET ||
    media === Utils.media.DESKTOP
  );

  const isSwedish = lang === "swedish";

  let title = "Dälje – Gustav Vasas bergbrott vid Horn";
  if (lang === "english") {
    title = "Stone and stories...";
  } else if (lang === "german") {
    title = "Stein und Geschichten...";
  }

  return (
    <Page title="Dälje – Gustav Vasas bergbrott vid Horn | Mya idé">
      <section>
        <div className="fossil-page">
          <div className="image-wrapper">
            <Image
              alt="Stenar i vattnet"
              src="/images/b_stones_in_water_1390x500.jpg"
              ratio={1390 / 500}
            />
          </div>

          <div
            className={cx("calendar", {
              full: useFullWidth,
            })}
          >
            <Dates useFullWidth={useFullWidth} lang={lang} />

            <div className="calendar-info fossil">
              <div className="calendar-type">
                <div className="calendar-type-header">
                  <div className="bg" />
                  <h3>{title}</h3>
                </div>
                {isSwedish ? (
                  <ul>
                    {/* <li>
                      Söndag{" "}
                      <span className="pictures-time">
                        <b>30 / 7</b>,
                      </span>
                      kl 13 - 15
                    </li> */}
                    <li>
                      <strong>200 kr</strong> per person
                    </li>
                    <li>
                      <u>Anmälan till 0707 - 53 78 90</u>, gärna SMS
                    </li>
                    <li>Ojämn terräng</li>
                    <li>
                      <span>Mötesplats:</span> södra Horns udde, 3 km norr
                      Alvedsjöbodar
                    </li>
                    <li>
                      <span>Horns udde:</span>{" "}
                      <AppLink
                        external
                        to="https://goo.gl/maps/UxHr7VHFZDJz1p3c7"
                      >
                        Latitud 57.193626, longitud 16.903270
                      </AppLink>
                    </li>
                  </ul>
                ) : null}
                <div className="small">
                  Bakom stenhögar hittar vi en fantasieggande strand med en
                  kaskad av färger och former. Vägen dit ner kantas av den röda
                  kalkstenen i Gustav Vasas eget bergbrott. Här hittar vi den
                  öländska marmor som under några århundraden stod på alla
                  förnäma byggherrars önskelista. Vi tar tillsammans en lättsam
                  geologisk resa tillbaka till stenens begynnelse och förlorar
                  oss kort i miljarderna år. Förstenade avtryck vittnar om de
                  första djuren och vi lyssnar till vad stenarna på stranden har
                  att berätta.
                </div>
                {isSwedish ? (
                  <ul>
                    <li>
                      <span>Kontakt:</span> Ann-Charlotte Garhammar
                    </li>
                    <li>
                      <span>Telefon:</span> 0707 537890
                    </li>
                  </ul>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Page>
  );
};

Fossil.propTypes = {
  media: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
};

export default Fossil;
