import React from 'react';

import './Logo.scss';

const Logo = () => (
    <svg className="logo-icon" width="24" height="44" viewBox="0 0 24 44">
        <path d="M2 32 L2 2 L12 12 L22 2 L22 42"/>
        <path d="M2 12 L12 22 L22 12"/>
        <path d="M12 22 L12 32 L22 32"/>
        <path d="M2 42 L22 22"/>
    </svg>
);

export default Logo;
