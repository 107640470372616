/* globals google, window, document */

import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Utils from "../../utils/Utils";
import Calendar from "./Calendar";

class CalendarContainer extends React.Component {
  componentDidMount() {
    window.initMap = function initMap() {
      // Create a map object and specify the DOM element for display.
      const map = new google.maps.Map(document.getElementById("map"), {
        // eslint-disable-line no-new
        center: { lat: 57.110761, lng: 16.894947 },
        zoom: 9,
        styles: [
          {
            featureType: "administrative",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#444444",
              },
            ],
          },
          {
            featureType: "administrative.locality",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#a9a9a9",
              },
            ],
          },
          {
            featureType: "landscape",
            stylers: [
              {
                color: "#f5f5f5",
              },
            ],
          },
          {
            featureType: "poi",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road",
            stylers: [
              {
                saturation: -100,
              },
              {
                lightness: 45,
              },
            ],
          },
          {
            featureType: "road.arterial",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road.highway",
            stylers: [
              {
                visibility: "simplified",
              },
            ],
          },
          {
            featureType: "transit",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "water",
            stylers: [
              {
                color: "#ffffff",
              },
              {
                visibility: "on",
              },
            ],
          },
        ],
      });

      // const imagesMarker = new google.maps.Marker({
      //   // eslint-disable-line no-new
      //   position: { lat: 57.109234, lng: 16.88965 },
      //   icon: {
      //     path: google.maps.SymbolPath.CIRCLE,
      //     fillColor: "#e6c9e4",
      //     fillOpacity: 1,
      //     scale: 8,
      //     strokeColor: "#b59bb3",
      //     strokeOpacity: 1,
      //     strokeWeight: 1,
      //   },
      //   map,
      // });

      const shinesMarker = new google.maps.Marker({
        // eslint-disable-line no-new
        position: { lat: 57.222592, lng: 16.951993 },
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          fillColor: "#e6c9e4",
          fillOpacity: 1,
          scale: 8,
          strokeColor: "#b59bb3",
          strokeOpacity: 1,
          strokeWeight: 1,
        },
        map,
      });

      // const stoneMarker = new google.maps.Marker({
      //   // eslint-disable-line no-new
      //   position: { lat: 57.109634, lng: 16.902364 },
      //   icon: {
      //     path: google.maps.SymbolPath.CIRCLE,
      //     fillColor: "#a7deae",
      //     fillOpacity: 1,
      //     scale: 8,

      //     strokeColor: "#8cbd92",
      //     strokeOpacity: 1,
      //     strokeWeight: 1,
      //   },
      //   map,
      // });

      // const fossilMarker =
      // new google.maps.Marker({
      //   // eslint-disable-line no-new
      //   position: { lat: 57.193626, lng: 16.90327 },
      //   icon: {
      //     path: google.maps.SymbolPath.CIRCLE,
      //     fillColor: "#92c6ff",
      //     fillOpacity: 1,
      //     strokeColor: "#6899d0",
      //     strokeOpacity: 1,
      //     strokeWeight: 1,
      //     scale: 8,
      //   },
      //   map,
      // });

      const churchMarker = new google.maps.Marker({
        // eslint-disable-line no-new
        position: { lat: 57.111394675445396, lng: 16.986270983022997 },
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          fillColor: "#a7deae",
          fillOpacity: 1,
          scale: 8,

          strokeColor: "#8cbd92",
          strokeOpacity: 1,
          strokeWeight: 1,
        },
        map,
      });

      // const byerumMarker =
      // new google.maps.Marker({
      //   // eslint-disable-line no-new
      //   position: { lat: 57.222592, lng: 16.951993 },
      //   icon: {
      //     path: google.maps.SymbolPath.CIRCLE,
      //     fillColor: "#f1e6d7",
      //     fillOpacity: 1,
      //     scale: 8,

      //     strokeColor: "#d2b58f",
      //     strokeOpacity: 1,
      //     strokeWeight: 1,
      //   },
      //   map,
      // });

      // const boneMarker = new google.maps.Marker({
      //   // eslint-disable-line no-new
      //   position: { lat: 57.109634, lng: 16.919364 },
      //   icon: {
      //     path: google.maps.SymbolPath.CIRCLE,
      //     fillColor: "#fab4af",
      //     fillOpacity: 1,
      //     scale: 8,
      //     strokeColor: "#d88c87",
      //     strokeOpacity: 1,
      //     strokeWeight: 1,
      //   },
      //   map,
      // });

      map.addListener("zoom_changed", () => {
        const zoom = map.getZoom();

        if (zoom < 11) {
          // imagesMarker.setPosition({ lat: 57.111299, lng: 16.88965 });
          // shinesMarker.setPosition({ lat: 57.223592, lng: 16.954993 });
          stoneMarker.setPosition({ lat: 57.109634, lng: 16.902364 });
          // boneMarker.setPosition({ lat: 57.108327, lng: 16.895271 });
        } else if (zoom < 13) {
          // imagesMarker.setPosition({ lat: 57.111009, lng: 16.894711 });
          // shinesMarker.setPosition({ lat: 57.223592, lng: 16.954993 });
          stoneMarker.setPosition({ lat: 57.109827, lng: 16.898975 });
          // boneMarker.setPosition({ lat: 57.108839, lng: 16.895852 });
        } else {
          // imagesMarker.setPosition({ lat: 57.110887, lng: 16.894707 });
          // shinesMarker.setPosition({ lat: 57.228592, lng: 16.959993 });
          stoneMarker.setPosition({ lat: 57.110739, lng: 16.895974 });
          // boneMarker.setPosition({ lat: 57.110241, lng: 16.895388 });
        }
      });
    };

    if (this.props.maps) {
      window.initMap();
      return;
    }

    window.setTimeout(() => {
      Utils.loadScript(
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyB4B1gbva3RTW1bNoOAhH8gGdHJD2mB1gQ&callback=initMap"
      )
        .then(this.props.mapLibLoaded)
        .catch((err) => {
          console.error("could not load map lib", err); // eslint-disable-line no-console
        });
    }, 300);
  }

  render() {
    return <Calendar {...this.props} />;
  }
}

CalendarContainer.propTypes = {
  mapLibLoaded: PropTypes.func.isRequired,
  maps: PropTypes.bool.isRequired,
  lang: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  media: state.ui.media,
  position: state.ui.position,
  direction: state.ui.direction,
  maps: state.libs.maps,
  lang: state.ui.lang,
});

const mapDispatchToProps = (dispatch) => ({
  mapLibLoaded: () => dispatch({ type: "LIBS_MAPS", payload: true }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CalendarContainer);
