import React from "react";
import ReactRouterPropTypes from "react-router-prop-types";
import PropTypes from "prop-types";
import AppLink from "../../ui/links/AppLink";
import texts from "../Header/HeaderTexts.json";
import "./MobileMenu.scss";

const MobileMenu = ({
  location,
  lang,
  toggleSwedish,
  toggleEnglish,
  toggleGerman,
  toggleMenu,
  isMenuOpen,
}) => {
  const { pathname } = location;

  function getClasses(linkUrl, path) {
    if (linkUrl === path) {
      return "mobile-menu-link active";
    }

    return "mobile-menu-link";
  }

  function getLangClasses(linkLang) {
    if (linkLang === lang) {
      return `mobile-link-lang-${linkLang} active`;
    }

    return `mobile-link-lang-${linkLang}`;
  }

  function onClick() {
    toggleMenu(!isMenuOpen);
  }

  return (
    <div className="mobile-menu">
      <div className="languages">
        <AppLink
          className={getLangClasses("swedish")}
          onClick={toggleSwedish}
          to={`${pathname}`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="16.25"
            viewBox="0 0 16 10"
          >
            <rect width="16" height="10" fill="#006aa7" />
            <rect width="2" height="10" x="5" fill="#fecc00" />
            <rect width="16" height="2" y="4" fill="#fecc00" />
          </svg>

          <div />
        </AppLink>
        {/* <AppLink
                    className={getLangClasses('english')}
                    onClick={toggleEnglish}
                    to={`${pathname === '/oland-spirar' ? '/' : pathname}?lang=english`}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 30" width="26" height="16.25" preserveAspectRatio="none">
                        <clipPath id="t">
                            <path d="M30,15 h30 v15 z v15 h-30 z h-30 v-15 z v-15 h30 z" />
                        </clipPath>
                        <path d="M0,0 v30 h60 v-30 z" fill="#00247d" />
                        <path d="M0,0 L60,30 M60,0 L0,30" stroke="#fff" strokeWidth="6" />
                        <path d="M0,0 L60,30 M60,0 L0,30" clipPath="url(#t)" stroke="#cf142b" strokeWidth="4" />
                        <path d="M30,0 v30 M0,15 h60" stroke="#fff" strokeWidth="10" />
                        <path d="M30,0 v30 M0,15 h60" stroke="#cf142b" strokeWidth="6" />
                    </svg>
                    <div />
                </AppLink> */}

        <AppLink
          className={getLangClasses("english")}
          onClick={toggleEnglish}
          to={`${pathname === "/sinnenas-oland" ? "/" : pathname}?lang=english`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 60 30"
            width="26"
            height="16.25"
            preserveAspectRatio="none"
          >
            <clipPath id="t">
              <path d="M30,15 h30 v15 z v15 h-30 z h-30 v-15 z v-15 h30 z" />
            </clipPath>
            <path d="M0,0 v30 h60 v-30 z" fill="#00247d" />
            <path d="M0,0 L60,30 M60,0 L0,30" stroke="#fff" strokeWidth="6" />
            <path
              d="M0,0 L60,30 M60,0 L0,30"
              clipPath="url(#t)"
              stroke="#cf142b"
              strokeWidth="4"
            />
            <path d="M30,0 v30 M0,15 h60" stroke="#fff" strokeWidth="10" />
            <path d="M30,0 v30 M0,15 h60" stroke="#cf142b" strokeWidth="6" />
          </svg>
          <div />
        </AppLink>

        <AppLink
          className={getLangClasses("german")}
          onClick={toggleGerman}
          to={`${pathname === "/sinnenas-oland" ? "/" : pathname}?lang=german`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="16.25"
            viewBox="0 0 5 3"
            preserveAspectRatio="none"
          >
            <rect
              id="black_stripe"
              width="5"
              height="3"
              y="0"
              x="0"
              fill="#000"
            />
            <rect
              id="red_stripe"
              width="5"
              height="2"
              y="1"
              x="0"
              fill="#D00"
            />
            <rect
              id="gold_stripe"
              width="5"
              height="1"
              y="2"
              x="0"
              fill="#FFCE00"
            />
          </svg>
          <div />
        </AppLink>
      </div>

      <div className="mobile-menu-links">
        <AppLink className={getClasses("/", pathname)} to="/" onClick={onClick}>
          {texts[lang].guide}
          <div />
        </AppLink>

        <AppLink
          className={getClasses("/bilder-i-natten", pathname)}
          to="/bilder-i-natten"
          onClick={onClick}
        >
          {texts[lang].images}
          <div />
        </AppLink>

        {/* <AppLink
                    className={'shines ' + getClasses('/oland-spirar', pathname)}
                    to={lang === 'swedish' ? '/oland-spirar' : '/'}
                >
                    {texts[lang].shines}
                    <div />
                </AppLink> */}
        <AppLink
          className={"senses " + getClasses("/sinnenas-oland", pathname)}
          to={lang === "swedish" ? "/sinnenas-oland" : "/"}
          onClick={onClick}
        >
          {texts[lang].senses}
          <div />
        </AppLink>

        <AppLink
          className={getClasses("/kontakt", pathname)}
          to="/kontakt"
          onClick={onClick}
        >
          {texts[lang].contact}
          <div />
        </AppLink>
      </div>
    </div>
  );
};

MobileMenu.propTypes = {
  location: ReactRouterPropTypes.location.isRequired, // eslint-disable-line react/no-typos
  lang: PropTypes.string.isRequired,
  toggleSwedish: PropTypes.func.isRequired,
  toggleEnglish: PropTypes.func.isRequired,
  toggleGerman: PropTypes.func.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
};

export default MobileMenu;
