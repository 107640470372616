import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import MobileMenu from './MobileMenu';

const mapDispatchToProps = dispatch => ({
    toggleSwedish: () =>
        dispatch({
            type: 'UI_SET_LANG',
            payload: 'swedish',
        }),
    toggleGerman: () =>
        dispatch({
            type: 'UI_SET_LANG',
            payload: 'german',
        }),
    toggleEnglish: () =>
        dispatch({
            type: 'UI_SET_LANG',
            payload: 'english',
        }),
    toggleMenu: isMenuOpen =>
        dispatch({
            type: 'UI_TOGGLE_MENU',
            payload: isMenuOpen,
        }),
});

const mapStateToProps = state => ({
    lang: state.ui.lang,
    isMenuOpen: state.ui.isMenuOpen,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MobileMenu));
