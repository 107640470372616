import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import App from './App';

const mapStateToProps = (state) => ({
    lang: state.ui.lang,
    initialized: state.ui.initialized,
    media: state.ui.media,
    position: state.ui.position,
    isMenuOpen: state.ui.isMenuOpen,
});

const mapDispatchToProps = dispatch => ({
    closeMenu: () =>
        dispatch({
            type: 'UI_TOGGLE_MENU',
            payload: false,
        }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
