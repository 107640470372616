import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Utils from "../../utils/Utils";
import Dates from "./Dates";
import Stone from "./Stone";
import Stories from "./Stories";
// import Fossil from "./Fossil";
import Images from "./Images";
import Shines from "./Shines";
import Byerum from "./Byerum";
import Church from "./Church";
// import Bone from "./Bone";
import "./Calendar.scss";
import { FocusGuiding } from "../Focus/FocusGuiding";

// 1. stone = Stenen berättar(green)
// 2. fossil = Sten, Strand, Flisa och Fossil(blue)
// 3. images = Bilder i natten(purple)
// 4. byerum = Vid Byerums raukar (beige)
// 5. bone = Sten och ben (red)

// Bonus:
// - Skumtimmens Öland
// - Sinnenas Öland

const Calendar = ({ media, position, direction, lang }) => {
  const useFullWidth = !(
    media === Utils.media.MOBILE ||
    media === Utils.media.LANDSCAPE ||
    media === Utils.media.TABLET ||
    media === Utils.media.DESKTOP
  );

  return (
    <div
      className={cx("calendar", {
        full: useFullWidth,
      })}
    >
      <Dates
        useFullWidth={useFullWidth}
        position={position}
        direction={direction}
        lang={lang}
      />

      <div className="calendar-info">
        <div className="calendar-type">
          <Shines lang={lang} />
        </div>

        <div className="calendar-type">
          <Church lang={lang} />
        </div>

        <div className="calendar-type">
          <Stone lang={lang} />
        </div>

        <div className="calendar-type">
          <Stories lang={lang} />
        </div>

        <div className="calendar-type">
          <Byerum lang={lang} />
        </div>

        {/* <div className="calendar-type">
          <Bone lang={lang} />
        </div> */}

        <FocusGuiding />

        <div id="map" />
      </div>
    </div>
  );
};

Calendar.propTypes = {
  media: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  lang: PropTypes.string.isRequired,
};

export default Calendar;
