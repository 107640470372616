import React from "react";
import Image from "../../ui/Image/Image";
import "./FocusGuiding.scss";

export const FocusGuiding = () => {
  return null;
  return (
    <div className="focus-guiding">
      <h4>Guidning i fokus:</h4>
      {/* <h4>Välkommen under skördefesten till guidning i Källa g:a kyrka</h4> */}

      <ul>
        {/* <li>
          Lördag <b>13 / 5</b>, kl 11 - 12
        </li>
        <li>
          Fredag <b>29 / 9</b>, kl 11 - 12
        </li> */}
        <li>
          <b>150 kr</b> per person inkl. inträde i kyrkan (minimum 6 st)
        </li>
      </ul>

      <Image
        src="/images/kalla_1000x713.jpg"
        ratio={1000 / 713}
        alt="Källa kyrka"
      />
      <strong>En kyrka som rymmer 1000 års historia.</strong>
      <p>
        Källa gamla kyrka är en försvarskyrka från 1000-talet. Här talar
        kalkstensväggar om tider då pilgrimer kom för att hedra St Olof,
        handelsmännens och sjöfararnas helgon. Hans källa finns fortfarande att
        finna på kyrkans baksida. Men väggarna har också skyddat från pirater
        och tjänstgjort som kyrkorum i en begynnande kristen tid. Länge lät man
        kyrkan förfalla och under 1900-talets första hälft kallades den för
        ödekyrka. Numera eggar den vår historiska fantasi - inte minst genom de
        gamla kalkstensgravarna med nästan söndervittrade bomärken. Bakom kyrkan
        finns källan som liksom alla källor har en egen historia att berätta.
      </p>
      <ul>
        {/* <li>Bestäm tid så möter jag upp. Ca 60 min.</li>
        <li>Inträde i kyrkan (högsäsong): 20 kr/person.</li>
        <li>Inträde i kyrkan (högsäsong): 20 kr/p.</li>
        <li>Guidning: 750 kr/tim.</li>
        <li>150 kr/person (minimum 6 st)</li> */}
        <li>Svår tröskel för rullstolsburna.</li>
      </ul>
    </div>
  );
};
