import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from './Header';


const HeaderContainer = ({ toggleSwedish, toggleEnglish, toggleGerman, lang, location, toggleMenu, isMenuOpen }) => (
    <Header
        location={location}
        lang={lang}
        toggleSwedish={toggleSwedish}
        toggleEnglish={toggleEnglish}
        toggleGerman={toggleGerman}
        toggleMenu={toggleMenu}
        isMenuOpen={isMenuOpen}
    />
);

HeaderContainer.propTypes = {
    location: ReactRouterPropTypes.location.isRequired, // eslint-disable-line react/no-typos
    lang: PropTypes.string.isRequired,
    toggleSwedish: PropTypes.func.isRequired,
    toggleEnglish: PropTypes.func.isRequired,
    toggleGerman: PropTypes.func.isRequired,
    toggleMenu: PropTypes.func.isRequired,
    isMenuOpen: PropTypes.bool.isRequired,
}

const mapDispatchToProps = dispatch => ({
    toggleSwedish: () =>
        dispatch({
            type: 'UI_SET_LANG',
            payload: 'swedish',
        }),
    toggleGerman: () =>
        dispatch({
            type: 'UI_SET_LANG',
            payload: 'german',
        }),
    toggleEnglish: () =>
        dispatch({
            type: 'UI_SET_LANG',
            payload: 'english',
        }),
    toggleMenu: isMenuOpen =>
        dispatch({
            type: 'UI_TOGGLE_MENU',
            payload: isMenuOpen,
        }),
});

const mapStateToProps = state => ({
    lang: state.ui.lang,
    isMenuOpen: state.ui.isMenuOpen,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderContainer));
