import React from "react";
import AppLink from "../../ui/links/AppLink";

const Byerum = ({ lang }) => {
  if (lang === "swedish") {
    return (
      <div className="byerum">
        <div className="calendar-type-header">
          <div className="bg" />
          <h3>Vid Byrums raukar</h3>
        </div>
        <i className="calendar-type-sub-header">
          Kom till en lättsam guidning om stenstatyer och öländsk geologi.
          <br />
          <br />
          Vi möts vid P-platsen söder om Byrums raukar för att promenera ett
          hundratal meter längs kusten. Med blicken fäst vid Blå Jungfrun
          berättas om de miljoner och tusentals år som format landskapet vi ser
          och stenen vi går på.
        </i>
        <AppLink to="/vid-byrums-raukar">
          <b>Läs mer</b>
        </AppLink>
      </div>
    );
  } else if (lang === "english") {
    return (
      <div className="byerum">
        <div className="calendar-type-header">
          <div className="bg" />
          <h3>By the shore of Byrum</h3>
        </div>
      </div>
    );
  } else {
    return (
      <div className="byerum">
        <div className="calendar-type-header">
          <div className="bg" />
          <h3>Am Ufer des Byrum</h3>
        </div>
      </div>
    );
  }
};

export default Byerum;
