import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Page from '../../pages/Page';
import Cms from '../../components/Cms/Cms';

import texts from './NoMatchTexts.json';

import './NoMatch.scss';

const NoMatch = ({ lang }) => {

    const { title } = texts[lang];

    return (
        <Page title={title} className="no-match">
            <section>
                <article>
                    <Cms html={texts[lang].nomatch} />
                </article>
            </section>
        </Page>
    );
};

NoMatch.propTypes = {
    lang: PropTypes.string.isRequired,
}

const mapStateToProps = state => ({
    lang: state.ui.lang,
});

export default connect(mapStateToProps)(NoMatch);
