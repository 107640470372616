import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Page from "../Page";
import Dates from "../../components/Calendar/Dates";
import Utils from "../../utils/Utils";
import AppLink from "../../ui/links/AppLink";
import Image from "../../ui/Image/Image";

import "./Byerum.scss";

const Byrum = (props) => {
  const { lang, media } = props;
  const useFullWidth = !(
    media === Utils.media.MOBILE ||
    media === Utils.media.LANDSCAPE ||
    media === Utils.media.TABLET ||
    media === Utils.media.DESKTOP
  );

  const isSwedish = lang === "swedish";

  let title = "Vid Byrums raukar";
  if (lang === "english") {
    title = "By the shore of Byrum";
  } else if (lang === "german") {
    title = "Am Ufer des Byrum";
  }

  return (
    <Page title="Vid Byrums raukar | Mya idé">
      <section>
        <div className="byerum-page">
          <div className="image-wrapper">
            <Image
              alt="Ann-Charlotte"
              src="/images/portrait_1390x550.jpg"
              ratio={1390 / 550}
            />
          </div>
          <div
            className={cx("calendar", {
              full: useFullWidth,
            })}
          >
            <Dates useFullWidth={useFullWidth} lang={lang} />
            <div className="calendar-info byerum">
              <div className="calendar-type">
                <div className="calendar-type-header">
                  <div className="bg" />
                  <h3>{title}</h3>
                </div>
                {isSwedish ? (
                  <ul>
                    {/* <li>
                      <b className="pictures-time">12 / 5</b>kl 11 - 12.30{" "}
                      <br />
                      <b className="pictures-time">13 / 8</b>kl 11 - 12.30
                    </li> */}
                    <li>
                      <strong>150 kr</strong> per person
                    </li>
                    <li>
                      <u>Anmälan till 0707 - 53 78 90</u>, gärna SMS
                    </li>
                    <li>
                      <span>Mötesplats:</span> Byrums raukar, södra p-platsen
                    </li>
                    <li>
                      <span>Koordinater:</span>{" "}
                      <AppLink
                        external
                        to="https://goo.gl/maps/aBdea5WPd6q4gY3p8"
                      >
                        Latitud 57,222592, longitud 16,951993
                      </AppLink>
                    </li>
                  </ul>
                ) : null}
                <p className="small">
                  Lättsamt om den öländska kalkstenen och om stenarna du hittar
                  på stranden. Fossil leder oss tillbaka till svunnen tid och
                  färger, prickar och ränder i stenen berättar om jordens
                  spännande utveckling. Vi fördjupar oss i steniga fakta och
                  förlorar oss i miljontalet år och om vi letar hittar vi urtida
                  bläckfiskar som simmar i stenen.
                </p>
                {isSwedish ? (
                  <ul>
                    <li>
                      <span>Kontakt:</span> Ann-Charlotte Garhammar
                    </li>
                    <li>
                      <span>Telefon:</span> 0707 537890
                    </li>
                  </ul>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Page>
  );
};

Byrum.propTypes = {
  media: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
};

export default Byrum;
