import React from "react";
import PropTypes from "prop-types";
import Page from "../Page";
import Image from "../../ui/Image/Image";
import CalendarContainer from "../../components/Calendar/CalendarContainer";
import Cms from "../../components/Cms/Cms";
import Message from "../../ui/Messages/Message";
import AppLink from "../../ui/links/AppLink";
import texts from "./HomeTexts.json";

import "./Home.scss";
import School from "./School";

const Home = ({ lang, media }) => (
  <Page title="Mya idé" className="home">
    <section>
      <div className="home-top">
        <div className="home-intro">
          <div className="home-intro-inner">
            <h1>{texts[lang].header}</h1>

            <Cms html={texts[lang].intro} />

            <div className="avatar">
              <Image
                alt="Ann-Charlotte Garhammar"
                src="/images/mor_round_small.jpg"
                ratio={1}
              />
            </div>
          </div>
        </div>

        <div className="home-image">
          <Image
            alt="Byrums raukar"
            src="/images/bg_raukar_ingrid.jpg"
            ratio={1800 / 945}
          />
        </div>
      </div>

      <div className="guidings-wrapper">
        <Message type="INFO" lang={lang} langFilter={["swedish"]}>
          <div className="text-large">
            <p>Välkomna igen till Öland 2025 ❤️</p>
            {/* <ul>
              <li>
                Byrums raukar, fredag 10 maj 2024,&nbsp;{" "}
                <AppLink to="/oland-spirar">
                  <b>Stenen som får Öland att spira!</b>
                </AppLink>
              </li>

              <li>
                Källa gamla kyrka, lördag 11 maj 2024,&nbsp;{" "}
                <AppLink to="/kalla-gamla-kyrka">
                  <b>Kalkstenstemplet i Källa</b>
                </AppLink>
              </li>
            </ul> */}
          </div>
          <div className="mt-20">
            <Cms html={texts[lang].end} />
          </div>

          {/* <Cms html={texts[lang].spirar} /> */}
        </Message>
        {/* <Message type="ERROR" lang={lang} langFilter={["swedish"]}>
          <Cms html={texts[lang].cancelled} />
        </Message> */}

        <div className="guidings">
          <Cms html={texts[lang].tours} />
        </div>
        {/* <div className="mt-40">
          <School lang={lang} media={media} />
        </div> */}
      </div>

      <CalendarContainer />
    </section>
  </Page>
);

Home.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default Home;

/*

<Image
    alt="Öland sandvik"
    src="/images/water_bg_1.jpg"
    ratio={2530 / 1227}
/>

<Image
    alt="Öland sandvik"
    src="/images/water_bg_1.jpg"
    ratio={2530 / 1227}
/>

<Image
    alt="Öland sandvik"
    src="/images/sandvik_landscape_blue_filter.jpg"
    ratio={2667 / 1450}
/>

<Image
    alt="Öland sandvik"
    src="/images/sandvik_landscape.jpg"
    ratio={2667 / 1450}
/>
*/
