/* globals window, document, fetch */

export default class Utils {
    static onNextFrame(callback) {
        setTimeout(() => window.requestAnimationFrame(callback), 0);
    }

    static deepUpdate(obj, pathString, newValue) {
        const path = pathString.split('.');

        const clone = { ...obj };
        let newPart = clone;
        let pointer = newPart;

        for (let i = 0; i < path.length - 1; i++) {
            newPart = { ...newPart[path[i]] };
            pointer[path[i]] = newPart;
            pointer = newPart;
        }

        newPart[path[path.length - 1]] = newValue;

        return clone;
    }


    static get widths() {
        return {
            $landscape: 480,
            $tablet: 768,
            $desktop: 1024,
            $desktopSuper: 1440,
            $wide: 1600,
        };
    }

    static get media() {
        return {
            MOBILE: 'MOBILE',
            LANDSCAPE: 'LANDSCAPE',
            TABLET: 'TABLET',
            DESKTOP: 'DESKTOP',
            DESKTOP_SUPER: 'DESKTOP_SUPER',
            WIDE: 'WIDE',
        };
    }

    static get status() {
        return {
            DEFAULT: 'DEFAULT',
            PENDING: 'PENDING',
            ERROR: 'ERROR',
            WARNING: 'WARNING',
            INFO: 'INFO',
            SUCCESS: 'SUCCESS',
        };
    }

    static getMediaFromWidth(width) {
        if (width < this.widths.$landscape) {
            return this.media.MOBILE;
        }

        if (width < this.widths.$tablet) {
            return this.media.LANDSCAPE;
        }

        if (width < this.widths.$desktop) {
            return this.media.TABLET;
        }

        if (width < this.widths.$desktopSuper) {
            return this.media.DESKTOP;
        }

        if (width < this.widths.$wide) {
            return this.media.DESKTOP_SUPER;
        }

        return this.media.WIDE;
    }

    // https://developer.mozilla.org/en-US/docs/Web/API/HTMLScriptElement
    static loadScript(sSrc) {
        return new Promise((resolve, reject) => {
            const oScript = document.createElement('script');
            oScript.type = 'text/javascript';
            oScript.onerror = reject;
            oScript.onload = resolve;
            oScript.async = true;
            document.head.appendChild(oScript);
            oScript.src = sSrc;
        });
    }

    static loadJson(url) {
        return fetch(url).then(resp => resp.json());
    }
}
