import React from "react";
import AppLink from "../../ui/links/AppLink";

const Stone = ({ lang }) => {
  if (lang === "swedish") {
    return (
      <div className="stone">
        <div className="calendar-type-header">
          <div className="bg" />
          <h3>4,6 miljarder år på 60 min</h3>
        </div>
        <i className="calendar-type-sub-header">
          Om urtida djur och öländska lager av sand, lera och kalkskal. Vid
          Gillbergas höga stenvägg möts vi av mäktighet och evighet.
        </i>
        <AppLink to="/stenen-berättar">
          <b>Läs mer</b>
        </AppLink>
      </div>
    );
  } else if (lang === "english") {
    return (
      <div className="stone">
        <div className="calendar-type-header">
          <div className="bg" />
          <h3>Stone and stories...</h3>
        </div>
      </div>
    );
  } else {
    return (
      <div className="stone">
        <div className="calendar-type-header">
          <div className="bg" />
          <h3>Stein und Geschichten...</h3>
        </div>
      </div>
    );
  }
};

export default Stone;
