import React from "react";
import ReactRouterPropTypes from "react-router-prop-types";
import PropTypes from "prop-types";
import Logo from "../../ui/icons/Logo";
import AppLink from "../../ui/links/AppLink";

import texts from "./HeaderTexts.json";

import "./Header.scss";

const Header = ({
  location,
  lang,
  toggleSwedish,
  toggleEnglish,
  toggleGerman,
  toggleMenu,
  isMenuOpen,
}) => {
  const { pathname } = location;

  function getClasses(linkUrl, path) {
    if (linkUrl === path) {
      return "menu-link active";
    }

    return "menu-link";
  }

  function onClick(ev) {
    ev.preventDefault();
    toggleMenu(!isMenuOpen);
  }

  return (
    <div className="header-wrapper">
      <header>
        <div className="logo">
          <AppLink to="/" className="logo-link">
            <Logo />
            Mya idé
          </AppLink>
        </div>

        <nav>
          <AppLink className={getClasses("/", pathname)} to="/">
            {texts[lang].guide}
            <div />
          </AppLink>

          {/* <AppLink
            className={getClasses("/bilder-i-natten", pathname)}
            to="/bilder-i-natten"
          >
            {texts[lang].images}
            <div />
          </AppLink> */}

          {/* <AppLink
            className={"shines " + getClasses("/oland-spirar", pathname)}
            to={lang === "swedish" ? "/oland-spirar" : "/"}
          >
            {texts[lang].shines}
            <div />
          </AppLink> */}

          <AppLink
            className={"senses " + getClasses("/sinnenas-oland", pathname)}
            to={lang === "swedish" ? "/sinnenas-oland" : "/"}
          >
            {texts[lang].senses}
            <div />
          </AppLink>

          <AppLink className={getClasses("/kontakt", pathname)} to="/kontakt">
            {texts[lang].contact}
            <div />
          </AppLink>

          <AppLink
            className="mobile-menu-bars"
            onClick={onClick}
            aria="Öppna mobilmenyn"
          >
            <span className="mobile-menu-bars-wrapper">
              <span className="mobile-menu-first-bar" />
              <span className="mobile-menu-second-bar" />
              <span className="mobile-menu-last-bar" />
            </span>
          </AppLink>
        </nav>

        <div className="languages">
          <AppLink
            className={lang === "swedish" ? "active" : ""}
            onClick={toggleSwedish}
            to={`${pathname}`}
            aria="Växla språk till svenska"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="16.25"
              viewBox="0 0 16 10"
            >
              <rect width="16" height="10" fill="#006aa7" />
              <rect width="2" height="10" x="5" fill="#fecc00" />
              <rect width="16" height="2" y="4" fill="#fecc00" />
            </svg>

            <div />
          </AppLink>
          <AppLink
            className={lang === "english" ? "active" : ""}
            onClick={toggleEnglish}
            to={
              pathname === "/sinnenas-oland"
                ? `/?lang=english`
                : `${pathname}?lang=english`
            }
            aria="Växla språk till svenska"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 60 30"
              width="26"
              height="16.25"
              preserveAspectRatio="none"
            >
              <clipPath id="t">
                <path d="M30,15 h30 v15 z v15 h-30 z h-30 v-15 z v-15 h30 z" />
              </clipPath>
              <path d="M0,0 v30 h60 v-30 z" fill="#00247d" />
              <path d="M0,0 L60,30 M60,0 L0,30" stroke="#fff" strokeWidth="6" />
              <path
                d="M0,0 L60,30 M60,0 L0,30"
                clipPath="url(#t)"
                stroke="#cf142b"
                strokeWidth="4"
              />
              <path d="M30,0 v30 M0,15 h60" stroke="#fff" strokeWidth="10" />
              <path d="M30,0 v30 M0,15 h60" stroke="#cf142b" strokeWidth="6" />
            </svg>
            <div />
          </AppLink>
          <AppLink
            className={lang === "german" ? "active" : ""}
            onClick={toggleGerman}
            to={
              pathname === "/sinnenas-oland"
                ? `/?lang=german`
                : `${pathname}?lang=german`
            }
            aria="Växla språk till svenska"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="16.25"
              viewBox="0 0 5 3"
              preserveAspectRatio="none"
            >
              <rect
                id="black_stripe"
                width="5"
                height="3"
                y="0"
                x="0"
                fill="#000"
              />
              <rect
                id="red_stripe"
                width="5"
                height="2"
                y="1"
                x="0"
                fill="#D00"
              />
              <rect
                id="gold_stripe"
                width="5"
                height="1"
                y="2"
                x="0"
                fill="#FFCE00"
              />
            </svg>
            <div />
          </AppLink>
        </div>
      </header>
    </div>
  );
};

Header.propTypes = {
  location: ReactRouterPropTypes.location.isRequired, // eslint-disable-line react/no-typos
  lang: PropTypes.string.isRequired,
  toggleSwedish: PropTypes.func.isRequired,
  toggleEnglish: PropTypes.func.isRequired,
  toggleGerman: PropTypes.func.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
};

export default Header;
