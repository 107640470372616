import React from 'react';
import PropTypes from 'prop-types';
import './Image.scss';

class Image extends React.PureComponent {
    static propTypes = {
        src: PropTypes.string.isRequired,
        ratio: PropTypes.number.isRequired,
        alt: PropTypes.string.isRequired,
        defaultSrc: PropTypes.string,
        className: PropTypes.string,
        callback: PropTypes.func,
    };

    static defaultProps = {
        className: '',
        defaultSrc:
            'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==',
        callback: null,
    };

    componentDidMount() {
        this.load();
    }

    load = () => {
        const src = this.image.getAttribute('data-src');
        const img = this.image.children[0];
        const cb = this.props.callback;
        img.onload = function onImageLoad() {
            this.classList.add('loaded');
            if (cb) {
                cb();
            }
        };

        img.src = src;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.src !== this.props.src) {
            this.load();
        }
    } 

    setImageRef = e => (this.image = e);

    render() {
        const { className, ratio, src, alt, defaultSrc } = this.props;
        const classes = `image ${className}`;
        const style = {
            paddingBottom: `${parseInt(100 / ratio, 10)}%`,
        };
        return (
            <div
                ref={this.setImageRef}
                className={classes}
                style={style}
                data-src={src}
            >
                <img src={defaultSrc} alt={alt} />
            </div>
        );
    }
}

export default Image;
