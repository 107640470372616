import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Page from "../Page";
import Dates from "../../components/Calendar/Dates";
import Utils from "../../utils/Utils";
import AppLink from "../../ui/links/AppLink";
import Image from "../../ui/Image/Image";

import "./Images.scss";

const Images = (props) => {
  const { lang, media } = props;
  const useFullWidth = !(
    media === Utils.media.MOBILE ||
    media === Utils.media.LANDSCAPE ||
    media === Utils.media.TABLET ||
    media === Utils.media.DESKTOP
  );

  const isSwedish = lang === "swedish";

  let title = "Bilder i natten / Bildspel";
  if (lang === "english") {
    title = "Pictures in the night";
  } else if (lang === "german") {
    title = "Bilder in der Nacht";
  }

  return (
    <Page title="Bilder i natten | Mya idé">
      <section>
        <div className="images-page">
          <div className="image-wrapper">
            <Image
              alt="Gillberga bergbrott i solnedgången"
              src="/images/images_1390x449.jpg"
              ratio={1390 / 449}
            />
          </div>

          <div
            className={cx("calendar", {
              full: useFullWidth,
            })}
          >
            <Dates useFullWidth={useFullWidth} lang={lang} />
            <div className="calendar-info images">
              <div className="calendar-type">
                <div className="calendar-type-header">
                  <div className="bg" />
                  <h3>{title}</h3>
                </div>
                {isSwedish ? (
                  <ul>
                    <li className="hide">
                      <b>Obs!</b> Olika starttider pga av när skymningsljuset
                      infaller.
                    </li>
                    {/* <li>
                      <b className="pictures-time">19 / 7</b> kl 22.00
                      <br />
                      <div className="pictures-extra">
                        <span>
                          <b className="pictures-time">26 / 7</b> kl 21.45
                        </span>
                        <span className="pictures-additional">
                          <span className="date-notifier" />
                          Tillsammans med blomguiden Eva Åkerstedt
                        </span>
                      </div>
                      <b className="pictures-time">2 / 8</b> kl 21.30
                      <br />
                      <b className="pictures-time">
                        <s>9 / 8</s>
                      </b>{" "}
                      <b>Inställt!</b>
                    </li> */}
                    <li>Ta varma kläder och gärna en stol att sitta på.</li>
                    <li>Inställt vid regn, ring vid tveksamt väder.</li>
                    <li>God tillgänglighet för funktionshindrade, vg ring.</li>
                    <li>
                      <span>Tid:</span> <strong>ca 45 minuter</strong>
                    </li>
                    <li>
                      <strong>150 kr</strong> per person
                    </li>
                    <li>
                      <span>Mötesplats:</span> Gillberga Natur- och Kulturbrott
                    </li>
                    <li>
                      <span>Koordinater:</span>{" "}
                      <AppLink external to="https://goo.gl/maps/taoQ5gP6B272">
                        Latitud 57.1110, longitud 16.8956
                      </AppLink>
                    </li>
                  </ul>
                ) : null}
                <p className="small">
                  Under knappa timmen berättas om kalksten, fossil och annat
                  förunderligt. Stora bilder visas direkt på bergväggen under
                  mörknande himmel och låter oss följa vår jords spännande
                  utveckling. Miljontalet år går över i tusentalet år som
                  vittnar om dagens landskap, om kungars hårda nypor och bondens
                  knogande med det steniga landskapet.
                </p>
                {isSwedish ? (
                  <ul>
                    <li>
                      <span>Kontakt:</span> Ann-Charlotte Garhammar
                    </li>
                    <li>
                      <span>Telefon:</span> 0707-537890
                    </li>
                  </ul>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Page>
  );
};

Images.propTypes = {
  media: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
};

export default Images;
