import React from 'react';
import Page from '../Page';
import Image from '../../ui/Image/Image';
import Utils from '../../utils/Utils';

import './Senses.scss';

const Senses = ({ lang, media, }) => {

    const small = (
        media === Utils.media.MOBILE ||
        media === Utils.media.LANDSCAPE
    );


    const image = small ? (
        <Image
            alt="Järnängel"
            src="/images/angel_m.jpg"
            ratio={1000 / 600}
        />
    ) : (
        <Image
            alt="Järnängel"
            src="/images/angel_d.jpg"
            ratio={1390 / 491}
        />
    );

    return (
        <Page title="Sinnenas Öland | Mya idé">

            <section>

                <div className="senses-page">
                
                    <div className="image-wrapper">
                        {image}
                    </div>
                
                    <div className="senses-inner">

                        <h2>Sinnenas Öland</h2>

                        <p>
                            <i>
                                Vi reser med våra sinnen på matens och konstens Öland. Vi njuter av vyerna, tingens skönhet och de öländska smakerna - medan kulturen kläs i ord.
                            </i>
                        </p>
                

                        <div>
                            <p>
                                Till Öland, landskapet med det förunderliga ljuset, har konstnärer sökt sig i alla tider.<br/>
                                Alvarslandskapet inspirerar till kulinariskt och kreativt skapande. Himmel och hav är ständigt närvarande!<br />
                                Under dagen gästar vi ett antal färgstarka konstnärssjälar och avnjuter lunch och kaffepaus i genuina miljöer.<br />
                                Detta är en resa för alla sinnen, en resa som kryddas med berättelser om och ur det öländska landskapet.<br />
                            </p>
                            <p>
                                Jag ger gärna ett förslag och följer er längs vägen.<br />
                                Ni kan välja dag och antal timmar och om ni har speciella önskemål så kommer de i möjligaste mån att uppfyllas. Kanske vill du fira något med trevlig dryck bland blomster på löväng, kanske vill du inta kaffe bland lämningar från järnåldern eller bjuda fruktfat nedan stenbrottets mäktiga vägg. Alternativen är många.
                            </p>
                        </div>
                        <div>
                            <p>
                                Kostnad för dagen är 775 kr/p – då ingår lunch och eftermiddagskaffe, men ni måste vara minst 10 personer och köra egna bilar.<br />
                                Är ni fler eller färre blir priset något annorlunda.<br />
                                En dag av sköna erfarenheter väntar.
                            </p>
                            <p>
                                Välkommen att höra av dig!
                            </p>

                            <dl>
                                <dt>Kontakt:</dt>
                                <dd><b>Ann-Charlotte Garhammar</b></dd>
                                <dt>Mobil:</dt>
                                <dd><b>070-753 7890</b></dd>
                                <dt>Email:</dt>
                                <dd><b>mya.oland@gmail.com</b></dd>
                            </dl>

                        </div>
                    </div>
                </div>
            </section>
        </Page>
    );
}

export default Senses;