import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import './AppLink.scss';

class AppLink extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        className: PropTypes.string,
        onClick: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        to: PropTypes.string,
        disabled: PropTypes.bool,
        external: PropTypes.bool,
        payload: PropTypes.shape({}),
        tabIndex: PropTypes.number,
    };

    static defaultProps = {
        className: '',
        onClick: null,
        onFocus: () => { },
        onBlur: () => { },
        to: '',
        disabled: false,
        external: false,
        payload: null,
        tabIndex: 0,
    };

    onClick = event => {
        const { disabled, onClick, payload } = this.props;

        // You don't have to provide an onClick to a RouterLink. But you might.
        if (!onClick) {
            return;
        }

        if (disabled) {
            event.preventDefault();
            return;
        }

        onClick(event, payload);
    };

    render() {
        const {
            className,
            to,
            disabled,
            tabIndex,
            external,
            children,
            onFocus,
            onBlur,
            aria,
        } = this.props;
        const tabIndexProp = disabled || tabIndex === -1 ? -1 : null;
        const classes = cx('link', className, {
            disabled,
        });

        let targetProps = {};
        if (aria) {
            targetProps['aria-label'] = aria;
        };

        if (external) {
            if (to.indexOf('mailto:') !== 0) {
                targetProps = {
                    target: '_blank',
                    rel: 'noopener',
                };
            }

            return (
                <a
                    className={classes}
                    href={to}
                    tabIndex={tabIndex}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    {...targetProps}
                >
                    {children}
                </a>
            );
        }

        if (to) {
            return (
                <Link
                    className={classes}
                    to={to}
                    tabIndex={tabIndex}
                    onClick={this.onClick}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    {...targetProps}
                >
                    {children}
                </Link>
            );
        }

        return (
            <button
                className={classes}
                onClick={this.onClick}
                tabIndex={tabIndexProp}
                onFocus={onFocus}
                onBlur={onBlur}
                {...targetProps}
            >
                {children}
            </button>
        );
    }
}

export default AppLink;
